exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index--.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-cancel-jsx": () => import("./../../../src/pages/shop/cancel.jsx" /* webpackChunkName: "component---src-pages-shop-cancel-jsx" */),
  "component---src-pages-shop-success-jsx": () => import("./../../../src/pages/shop/success.jsx" /* webpackChunkName: "component---src-pages-shop-success-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-catalog-jsx": () => import("./../../../src/templates/Catalog.jsx" /* webpackChunkName: "component---src-templates-catalog-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-project-single-jsx": () => import("./../../../src/templates/ProjectSingle.jsx" /* webpackChunkName: "component---src-templates-project-single-jsx" */)
}

