import React, { useEffect, createContext, useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Header from "./Header"
import Splash from "./Splash"
import useDeviceDetect from "../hooks/useDeviceDetect"
// import Footer from "./Footer"

const WrapperContext = createContext({})
const duration = 0.15
const variants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ children, pageContext, location }) => {
  const settings = {}
  const { isHome } = pageContext
  const { isMobile } = useDeviceDetect()
  // console.log(isMobile)

  useEffect(() => {
    // setTimeout(_detectScrollbarWidth, 150)
    _format()
    window.addEventListener("resize", _format)
    return () => {
      window.removeEventListener("resize", _format)
    }
  }, [isMobile])

  const _format = () => {
    setTimeout(_detectScrollbarWidth, 50)

    // console.log(window.innerWidth)
    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )

    let appHeight = window.innerHeight
    // if (isMobile) appHeight -= headerBounding.height * 2
    // console.log(isMobile)
    document.documentElement.style.setProperty("--app-height", appHeight + "px")
  }

  const _detectScrollbarWidth = () => {
    const headerWith = document
      .querySelector("header")
      .getBoundingClientRect().width
    const windowWidth = document
      .querySelector("main")
      .getBoundingClientRect().width

    // console.log(headerWith, windowWidth)
    if (windowWidth < headerWith) {
      document.documentElement.classList.add("has-sb-visible")
    } else {
      document.documentElement.classList.remove("has-sb-visible")
    }
  }

  return (
    <WrapperContext.Provider value={{ settings, location, isHome }}>
      <div id="page">
        <Header />

        <AnimatePresence>
          <motion.div
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            // ref={scrollRef}
          >
            <main>{children}</main>
          </motion.div>
        </AnimatePresence>
        {/* <Footer /> */}
        {isHome && <Splash />}
      </div>
    </WrapperContext.Provider>
  )
}

const useWrapper = () => useContext(WrapperContext)

export { WrapperContext, Layout, useWrapper }
