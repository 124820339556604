import React, { useState, useEffect } from "react"
import styled from "styled-components"
import clsx from "clsx"
import { publish, subscribe, unsubscribe } from "pubsub-js"
// import { Link } from "gatsby"
import { motion } from "framer-motion"
import MenuGroup from "./MenuGroup"
import SanityImage from "./SanityImage"
import useDeviceDetect from "../hooks/useDeviceDetect"
import VideoMp4 from "./ui/VideoMp4"

const Nav = styled.nav`
  position: relative;
  /* .group:not(:first-child) {
    margin-top: -1px;
  }
  .group:not(:first-child) .col-category .header-box {
    border-top: var(--border);
  } */
  .menu {
    /* max-height: calc(100vh - 20px); */
    overflow-y: auto;
    @media all and (max-width: 1079px) {
      max-height: calc(var(--app-height) - var(--header-height));
    }
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Menu = ({ nav, projects, navProjects }) => {
  const [show, setShow] = useState(false)
  const [thumbnail, setThumbnail] = useState()
  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    // const tokenEnd = subscribe("PROJECT_END", () => {
    //   setShow(true)
    // })
    const tokenClose = subscribe("NAV_CLOSE", () => {
      setTimeout(() => {
        setShow(false)
      }, 50)
    })

    const tokenRoute = subscribe("ROUTE_UPDATE", () => {
      setTimeout(() => {
        setShow(false)
        setThumbnail(null)
      }, 50)
    })

    const tokenThumbnail = subscribe("THUMBNAIL", (e, d) => {
      // console.log(d)
      if (d) setThumbnail(d)
      else setThumbnail(null)
    })

    return () => {
      unsubscribe(tokenClose)
      unsubscribe(tokenRoute)
      unsubscribe(tokenThumbnail)
    }
  }, [])

  useEffect(() => {
    if (show) {
      document.body.classList.add("is-nav-open")
      if (isMobile) publish("ABOUT_CLOSE")
    } else {
      document.body.classList.remove("is-nav-open")
    }
  }, [show, isMobile])

  const variants = {
    open: { opacity: 1, y: 0, with: "100%" },
    closed: {
      opacity: 0,
      y: "-100%",
      with: "100%",
      transition: { delay: 0.1 },
    },
  }
  const spring = {
    duration: 0.6,
    type: "tween",
    ease: [0.37, 0.01, 0, 0.98],
  }

  return (
    <Nav>
      <button
        className=" text-left block relative z-20"
        onClick={() => setShow(!show)}
      >
        <div className="cartouche-box">
          <div className="label">Index</div>
        </div>
      </button>
      <div className="overlay absolute top-0 pt-header-height z-10 h-screen w-screen -translate-y-1- pointer-events-none">
        <motion.div
          transition={spring}
          initial={variants.closed}
          animate={show ? "open" : "closed"}
          variants={variants}
        >
          <ul
            className={clsx(
              "menu hide-scroll-bar h-screen inline-block",
              // show ? "block" : "hidden"
              show ? "pointer-events-auto" : ""
            )}
          >
            {nav.map((li, i) => (
              <li className="group" key={i}>
                <MenuGroup input={li} />
              </li>
            ))}
            <li>
              <a href="/">
                <div className="cartouche-box b-t">
                  <div className="label">Home</div>
                </div>
              </a>
              {/* <Link to="/">
                <div className="cartouche-box b-t">
                  <div className="label">Home</div>
                </div>
              </Link> */}
            </li>
          </ul>
          {!isMobile && thumbnail && thumbnail.asset && (
            <div className="thumbnail-wrapper fixed -top-header-height right-0 w-full md:w-1/2 h-screen bg-primary z-0">
              <div className="thumbnail h-full">
                {thumbnail.asset.mimeType === "video/mp4" && (
                  <VideoMp4 input={thumbnail} />
                )}
                {thumbnail.asset.mimeType !== "video/mp4" && (
                  <SanityImage image={thumbnail} objectFit="cover" />
                )}
              </div>
              {/* <div className="absolute inset-0 bg-accent mix-blend-multiply- mix-blend-soft-light"></div> */}
            </div>
          )}
        </motion.div>
      </div>
    </Nav>
  )
}

export default Menu
