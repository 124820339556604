import React from "react"

const VideoMp4 = ({ input }) => {
  return (
    <div className="video-mp4 h-full">
      <video
        autoPlay
        muted={true}
        preload="auto"
        loop
        className="object-cover w-full h-full"
      >
        <source type="video/mp4" src={input.asset.url}></source>
      </video>
    </div>
  )
}

export default VideoMp4
