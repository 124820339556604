import React, { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"
// import clsx from "clsx"

const SummaryDetailFramer = ({ onOpen, summary, detail }) => {
  const [expand, setExpand] = useState(false)

  const controls = useAnimation()
  const variants = {
    expanded: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
  }

  useEffect(() => {
    if (expand) {
      controls.start("expanded")
    } else {
      controls.start("collapsed")
    }
  }, [expand, controls])

  // useEffect(() => {
  //   onOpen(expand)
  // }, [expand])

  return (
    <div className="summary-detail">
      <div
        className="summary cursor-pointer hover:bg-accent"
        onClick={() => setExpand(!expand)}
        onKeyUp={() => setExpand(!expand)}
        tabIndex="-1"
        role="button"
      >
        {summary}
      </div>
      <div className="detail">
        <motion.div
          initial="collapsed"
          className="z-0 overflow-hidden"
          animate={controls}
          transition={{ duration: 0.3 }}
          variants={variants}
        >
          {detail}
        </motion.div>
      </div>
    </div>
  )
}

export default SummaryDetailFramer
