import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { _linkResolver } from "../core/utils"
import { publish } from "pubsub-js"

const MenuItem = ({ input }) => {
  const [thumbnail, setThumbnail] = useState()

  useEffect(() => {
    if (input.thumbnail?.asset) {
      // _preloadImage()
      const img = new Image()
      img.src = input?.thumbnail?.asset.url
    }
  }, [input])

  // const _preloadVideo = async () => {
  //   const video = document.createElement("video")
  //   video.src = await __preloadVideo(input.thumbnailVideo.asset.url)
  // }

  // async function __preloadVideo(src) {
  //   console.log(src)
  //   const res = await fetch(src)
  //   const blob = await res.blob()
  //   return URL.createObjectURL(blob)
  // }

  useEffect(() => {
    // console.log(thumbnail)
    publish("THUMBNAIL", thumbnail)
  }, [thumbnail])

  const _onMouseEnter = () => {
    setTimeout(() => {
      if (input.thumbnailVideo) {
        setThumbnail(input.thumbnailVideo)
      } else {
        setThumbnail(input.thumbnail)
      }
    }, 10)
  }

  const _onMouseLeave = () => {
    setThumbnail(null)
  }

  return (
    <li>
      <Link
        to={_linkResolver(input)}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
      >
        <div className="cartouche-box  ">
          <div className="label ellipsis">{input.title}</div>
        </div>
      </Link>
      {/* <img src={input.thumbnail.asset.url} alt="" /> */}
      {/* <pre>{JSON.stringify(input.thumbnail.asset)}</pre> */}
    </li>
  )
}

export default MenuItem
