import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import { _linkResolver } from "../core/utils"

const SanityTexte = ({ input }) => {
  // console.log(input)
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style)
        switch (props.node.style) {
          case "h2":
            return <h2>{props.children}</h2>
          case "text-lg":
            return <p className="text-lg">{props.children}</p>
          case "text-xl":
            return <p className="text-xl">{props.children}</p>
          default:
            return <p>{props.children}</p>
        }
      },
    },

    marks: {
      // sup: ({ children }) => <sup>{children}</sup>,
      "text-left": ({ children }) => (
        <span className="block text-left">{children}</span>
      ),
      "text-center": ({ children }) => (
        <span className="block text-center">{children}</span>
      ),
      "text-right": ({ children }) => (
        <span className="block text-right">{children}</span>
      ),

      link: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        )
      },
      internalLink: ({ mark, children }) => {
        // console.log(mark, children)
        return <Link to={_linkResolver(mark.reference)}>{children}</Link>
      },
    },
  }
  //serializers={serializers}
  return (
    <div className="texte ">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
