import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import About from "./About"
import Menu from "./Menu"
import { _linkResolver } from "../core/utils"

const query = graphql`
  query {
    sanityHeader {
      siteName

      navProjects {
        category {
          title
        }
        projects {
          _type
          title
          slug {
            current
          }
          thumbnail: _rawThumbnail(resolveReferences: { maxDepth: 10 })
          thumbnailVideo {
            asset {
              url
              mimeType
            }
          }
        }
      }
      shopLink {
        label
        link {
          ... on SanityPage {
            _type
            slug {
              current
            }
          }
          ... on SanityCatalog {
            _type
            slug {
              current
            }
          }
        }
      }
    }
  }
`

const Header = () => {
  const {
    sanityHeader: { siteName, navProjects, shopLink },
  } = useStaticQuery(query)

  // console.log(shopLink)
  return (
    <header className="fixed top-0 left-0 z-50 w-screen ">
      <div className="flex">
        <div className="header-item index w-1/2 z-20">
          <Menu nav={navProjects} />
        </div>

        <div className="header-item contact z-10">
          <About siteName={siteName} />
        </div>

        <div className="header-item shop z-10">
          <Link to={_linkResolver(shopLink.link)}>
            <div className="cartouche-box">
              <div className="label">{shopLink.label}</div>
            </div>
          </Link>
        </div>
      </div>
    </header>
  )
}

export default Header
