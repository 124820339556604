import locales from "../../config/i18n"

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf("/") + 1)
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function _linkResolver(doc) {
  if (!doc._type) return "/"
  switch (doc._type) {
    case "project":
      return `/project/${doc.slug.current}`
    case "category":
      return `/category/${doc.slug.current}`
    case "catalog":
      return `/${doc.slug.current}`

    default:
      return `/${doc.slug.current}`
  }
}

export const _localizeText = text => {
  const locale = "fr-fr"

  return locales[locale] && locales[locale][text] ? locales[locale][text] : text
}

export const animateNumber = (start, end, duration) => {
  let startTimestamp = null
  const step = timestamp => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)

    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

// inspired by https://github.com/GoogleChrome/workbox/blob/3d02230f0e977eb1dc86c48f16ea4bcefdae12af/packages/workbox-core/src/_private/logger.ts

const styles = [
  `background: rebeccapurple`,
  `border-radius: 0.5em`,
  `color: white`,
  `font-weight: bold`,
  `padding: 2px 0.5em`,
].join(`;`)

export function debugLog(...args) {
  console.debug(`%cgatsby`, styles, ...args)
}

export const _isElementInViewport = el => {
  const rect = el.getBoundingClientRect()
  // console.log(rect)
  return (
    rect.top <= 10 &&
    rect.bottom >= (window.innerHeight || document.documentElement.clientHeight)
  )
  // return rect.bottom > 0 && (rect.top >=0 && rect.top <
  // return (
  //   rect.top >= 0 &&
  //   rect.left >= 0 &&
  //   rect.bottom <=
  //     (window.innerHeight || document.documentElement.clientHeight) &&
  //   rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  // )
}
