import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SanityTexte from "./SanityTexte"
import clsx from "clsx"
import { publish, subscribe, unsubscribe } from "pubsub-js"
import styled from "styled-components"
import SummaryDetailFramer from "./ui/SummaryDetailFramer"
import { motion } from "framer-motion"
import useDeviceDetect from "../hooks/useDeviceDetect"

const query = graphql`
  query {
    sanityAbout {
      bio: _rawBio(resolveReferences: { maxDepth: 10 })
      # contacts: _rawContacts(resolveReferences: { maxDepth: 10 })
      # # contacts {
      # #   label
      # #   link
      # # }
      # press: _rawPress(resolveReferences: { maxDepth: 10 })
      # clients: _rawClients(resolveReferences: { maxDepth: 10 })
      # exhibitions {
      #   year
      #   title
      #   location
      # }

      modulesAbout {
        ... on SanityTexte {
          _key
          _type
          title
          dropdown
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityExhibitions {
          _key
          _type
          title
          dropdown
          items {
            year
            title
            location
          }
        }
      }
    }
  }
`

const Container = styled.div`
  .inner {
    & > .item {
      @media all and (min-width: 1080px) {
        max-height: calc(100vh - var(--space-lg));
        .about-item {
          max-width: 16.6666666667vw;
        }
        .texte {
          max-width: calc(16.6666666667vw - var(--space-sm));
        }
      }
    }
    @media all and (max-width: 1080px) {
      /* overflow-y: auto; */
      /* height: 100vh; */

      & > .item.overflow-y-auto {
        /* max-height: none; */
        overflow-y: unset;
        max-height: none;
      }
    }
  }

  a:hover {
    color: var(--color-accent);
  }

  @media all and (max-width: 1080px) {
    .inner {
      overflow-y: auto;
      height: 100vh;
      padding-bottom: 140px;
      .bio .texte {
        padding-bottom: 140px;
      }
    }
    .summary h2 {
      height: 1.6666666666666667em;
      line-height: 1.6666666666666667em;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  @supports (-webkit-touch-callout: none) {
    /* background: red; */
    .inner {
      padding-bottom: 140px;
    }
  }
`

const About = ({ siteName }) => {
  const { sanityAbout } = useStaticQuery(query)
  const { bio, modulesAbout } = sanityAbout
  const [show, setShow] = useState(false)
  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    const tokenClose = subscribe("ABOUT_CLOSE", () => {
      setTimeout(() => {
        setShow(false)
      }, 50)
    })

    const token = subscribe("ROUTE_UPDATE", () => {
      setShow(false)
    })
    return () => {
      unsubscribe(tokenClose)
      unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (!isMobile) return
    if (show) {
      publish("NAV_CLOSE")
    }
  }, [isMobile, show])

  const variants = {
    open: {
      opacity: 1,
      y: 0,
      with: "100%",
      height: "100%",
      background: "white",
    },
    closed: {
      opacity: 0,
      y: "-100%",
      with: "100%",
      height: "100%",
      background: "white",
      transition: { delay: 0.1 },
    },
  }
  const spring = {
    duration: 0.6,
    type: "tween",
    ease: [0.37, 0.01, 0, 0.98],
  }

  return (
    <Container id="about" className="">
      <button
        className="text-left block relative"
        onClick={() => setShow(!show)}
      >
        <div className="cartouche-box">
          <div className="label">À propos</div>
        </div>
      </button>

      <div
        className={clsx(
          "overlay fixed  px-sm- md:right-0 w-full h-screen top-0 right-0 ",
          show ? "pointer-events-auto " : "pointer-events-none "
        )}
      >
        <motion.div
          transition={spring}
          initial={variants.closed}
          animate={show ? "open" : "closed"}
          variants={variants}
        >
          <div className="inner md:grid grid-rows-2 grid-cols-1 md:grid-cols-4 md:grid-rows-1 py-lg bg-primary- h-full-">
            <div className="hidden-sm"></div>
            <div className="hidden-sm"></div>
            <div className="item bio hide-scroll-bar overflow-y-auto pr-md pl-sm mb-lg md:mb-0">
              <SanityTexte input={bio} />
            </div>
            <div className="item hide-scroll-bar overflow-y-auto md:pr-md">
              {modulesAbout.map((item, i) => (
                <div key={item._key} className="about-item">
                  {!item.dropdown && (
                    <div className="pb-md  bb pl-sm ">
                      <div className="pt-2 ">
                        <SanityTexte input={item.texte} />
                      </div>
                    </div>
                  )}

                  {item.dropdown && (
                    <SummaryDetailFramer
                      summary={
                        <h2 className="bb pl-sm py-2 pt-3 ">{item.title}</h2>
                      }
                      detail={
                        <div className="pb-md pl-sm pt-2">
                          {item._type === "texte" && (
                            <SanityTexte input={item.texte} />
                          )}
                          {item._type === "exhibitions" && (
                            <div className="pt-2">
                              <ul className="exhibitions-list">
                                {item.items.map((li, i) => (
                                  <li key={i} className="mb-1e flex">
                                    <div className="year pr-md">{li.year}</div>
                                    <div>
                                      <div className="title ">{li.title}</div>
                                      <div className="location text-sm-">
                                        {li.location}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      }
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </Container>
  )
}

export default About
