import React from "react"
import MenuItem from "./MenuItem"

const MenuGroup = ({ input }) => {
  const { category, projects } = input
  return (
    <div className="grid- grid-cols-2- max-w-1/2- w-1/2 flex">
      <div className="col-category">
        <div className="cartouche-box b-t">
          <div className="label">{category.title}</div>
        </div>
      </div>
      <ul className="sub-menu pt-md- pt-header-height">
        {projects.map((item, i) => (
          <MenuItem input={item} key={i} />
        ))}
        {/* {projects.map((item, i) => (
          <MenuItem input={item} key={i} />
        ))} */}
      </ul>
    </div>
  )
}

export default MenuGroup
