//const website = require('./website')

module.exports = {
  "fr-fr": {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr-fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    projects: "Projets",
    about: "À propos",
    contact: "Contact",
    type: "Type",
    nature: "Nature",
    surface: "Superficie",
    capacity: "Capacité d'accueil",
  },
  "en-gb": {
    default: false,
    label: "En",
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_GB",
    projects: "Projects",
    about: "About",
    contact: "Contact",
  },
};
