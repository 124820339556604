import React from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { GatsbyImage } from "gatsby-plugin-image"
import sanityConfig from "../../config/sanity"

const SanityImage = ({
  image,
  caption,
  objectFit = "contain",
  width = 2000,
  // showPlaceholder = true,
}) => {
  const { asset } = image
  const { metadata } = asset

  // const width = width
  const height = width / metadata.dimensions.aspectRatio
  // console.log(width, height)
  // const imagePlaceholder = getImage(asset);
  const imageData = getGatsbyImageData(
    asset,
    {
      width: width,
      height: height,
      fit: "FILLMAX",
      placeholder: "BLURRED",
    },
    sanityConfig.sanity
  )
  // console.log(objectFit)

  return (
    <figure className="w-full h-full pointer-events-none">
      <GatsbyImage
        image={imageData}
        alt={caption ? caption : ""}
        objectFit={objectFit}
        // backgroundColor={metadata.palette.dominant.background}
        imgStyle={{
          aspectRatio:
            objectFit === "cover"
              ? `${metadata.dimensions.width} / ${metadata.dimensions.height}`
              : "",
        }}
      />
    </figure>
  )
}

export default SanityImage
