import React from "react"
import { motion } from "framer-motion"
import useDeviceDetect from "../hooks/useDeviceDetect"
import { useState } from "react"
import { useEffect } from "react"

const DesktopLogo = () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1460.1 193.3"
    // style="enable-background:new 0 0 1460.1 193.3;"
    // xml:space="preserve"
  >
    <path
      d="M151.9,190.1L84.6,80.6l65.9-77.4h-36.8L63.3,63c-14.2,17.1-24,28.6-32.3,41.9c1.3-18.2,1.9-37.4,1.9-56.1V3.2H0v186.9h32.8
	v-54.2L63,101.2l52.6,88.9H151.9z M273.1,149.5h-32.8c-2.7,10.9-11.2,19-28,19c-19.5,0-32.8-12-33.9-36.3h96.9
	c0.3-2.9,0.3-5.6,0.3-8.8c0-46.5-27-70-63.3-70c-38.2,0-65.7,23.2-65.7,70s27.5,70,65.7,70C245.3,193.3,269.4,176.5,273.1,149.5z
	 M212.2,78.2c17.1,0,29.1,10.4,31.2,31.5h-64.6C181.3,88.6,194.1,78.2,212.2,78.2z M324.3,190.1h29.9l51-133.5h-33.1l-16.6,46.5
	c-7.2,20.3-12.3,35.2-16.3,52.3c-4.3-17.1-9.1-32-16.3-52.3l-16.6-46.5h-33.1L324.3,190.1z M443.8,3.2h-32v29.9h32V3.2z M443.8,56.6
	h-32v133.5h32V56.6z M494.8,56.6h-30.7v133.5h32v-76.6c0-22.4,11.2-33.6,28.8-33.6c16.6,0,26.2,9.1,26.2,29.6v80.6h32v-85.2
	c0-35.2-18.2-51.5-44.6-51.5c-20,0-35.8,9.3-43.8,27.2L494.8,56.6L494.8,56.6z M785.2,118.5c-2.1,29.6-21.4,45.9-52.1,45.9
	c-35,0-58.2-22.7-58.2-67.8s23.2-67.8,58.2-67.8c30.7,0,49.9,16.3,52.1,45.9h34.2C816.4,27.2,783,0,733.1,0c-51,0-92.4,31-92.4,96.7
	s41.4,96.7,92.4,96.7c49.9,0,83.3-27.2,86.2-74.8L785.2,118.5L785.2,118.5z M894,193.3c38.4,0,65.7-23.5,65.7-70s-27.2-70-65.7-70
	s-65.7,23.5-65.7,70S855.6,193.3,894,193.3z M894,167.7c-19.8,0-32.6-14.7-32.6-44.3S874.3,79,894,79s32.6,14.7,32.6,44.3
	S913.8,167.7,894,167.7z M972.6,142.8c0,34.4,17.6,50.5,43.5,50.5c19.2,0,34.4-8.8,42.2-25.6v22.4h31V56.6h-32v77.7
	c0,21.6-10.9,32.6-27.5,32.6c-16,0-25.1-9.1-25.1-28.6V56.6h-32L972.6,142.8L972.6,142.8z M1141.2,3.2h-32v186.9h32V3.2z
	 M1193.6,3.2h-32v29.9h32V3.2z M1193.6,56.6h-32v133.5h32V56.6z M1333.8,190.1c-7.7-17.9-8-20.3-8-37.4V95.9
	c0-28-21.6-42.5-57.1-42.5c-35.8,0-57.4,13.9-57.4,41.7h32.3c0.3-11.2,9.9-16.8,25.1-16.8c16,0,25.1,5.3,25.1,17.1
	c0,31.5-87.3,1.3-87.3,58.5c0,25.6,18.7,39,43,39c20,0,36-9.1,44.3-24.8l7.8,22.2h32.2V190.1z M1239.3,150.1
	c0-22.2,35.2-20.6,54.5-32.6v16.8c0,22.2-15.5,34.2-31.8,34.2C1247.5,168.5,1239.3,162.9,1239.3,150.1z M1343.4,142.8
	c0,34.4,17.6,50.5,43.5,50.5c19.2,0,34.4-8.8,42.2-25.6v22.4h31V56.6h-32v77.7c0,21.6-11,32.6-27.5,32.6c-16,0-25.1-9.1-25.1-28.6
	V56.6h-32L1343.4,142.8L1343.4,142.8z"
      fill="#001AFF"
    />
  </svg>
)
const MobileLogo = () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 291.5 142.6"
    // style="enable-background:new 0 0 291.5 142.6;"
    // xml:space="preserve"
  >
    <path
      d="M56.2,66.5l-23.9-39L55.8,0H42.7l-18,21.3c-5,6.1-8.5,10.2-11.5,14.9c0.5-6.5,0.7-13.3,0.7-19.9V0H2.2v66.5h11.7V47.2
	l10.7-12.3l18.7,31.6H56.2z M99.4,52.1H87.7c-0.9,3.9-4,6.7-10,6.7c-6.9,0-11.7-4.3-12.1-12.9h34.5c0.1-1,0.1-2,0.1-3.1
	c0-16.5-9.6-24.9-22.5-24.9c-13.6,0-23.4,8.3-23.4,24.9s9.8,24.9,23.4,24.9C89.5,67.6,98,61.7,99.4,52.1z M77.7,26.7
	c6.1,0,10.4,3.7,11.1,11.2h-23C66.7,30.4,71.2,26.7,77.7,26.7z M117.6,66.5h10.6L146.4,19h-11.8l-5.9,16.5
	c-2.6,7.2-4.4,12.5-5.8,18.6c-1.5-6.1-3.2-11.4-5.8-18.6L111.2,19H99.4L117.6,66.5z M160.1,0h-11.4v10.6h11.4V0z M160.1,19h-11.4
	v47.5h11.4V19z M178.2,19h-10.9v47.5h11.4V39.2c0-8,4-12,10.3-12c5.9,0,9.3,3.2,9.3,10.5v28.7h11.4V36.2c0-12.5-6.5-18.3-15.9-18.3
	c-7.1,0-12.7,3.3-15.6,9.7V19z M51.4,116c-0.8,10.5-7.6,16.3-18.5,16.3c-12.4,0-20.7-8.1-20.7-24.1s8.3-24.1,20.7-24.1
	c10.9,0,17.8,5.8,18.5,16.3h12.2c-1-16.9-12.9-26.6-30.7-26.6C14.7,73.9,0,84.9,0,108.3s14.7,34.4,32.9,34.4
	c17.8,0,29.6-9.7,30.7-26.6H51.4z M90.1,142.6c13.7,0,23.4-8.4,23.4-24.9s-9.7-24.9-23.4-24.9c-13.7,0-23.4,8.4-23.4,24.9
	S76.4,142.6,90.1,142.6z M90.1,133.5c-7,0-11.6-5.2-11.6-15.8S83.1,102,90.1,102s11.6,5.2,11.6,15.8S97.2,133.5,90.1,133.5z
	 M118.1,124.7c0,12.3,6.3,18,15.5,18c6.8,0,12.3-3.1,15-9.1v8h11V94h-11.4v27.6c0,7.7-3.9,11.6-9.8,11.6c-5.7,0-8.9-3.2-8.9-10.2V94
	h-11.4V124.7z M178.1,75h-11.4v66.5h11.4V75z M196.7,75h-11.4v10.6h11.4V75z M196.7,94h-11.4v47.5h11.4V94z M246.6,141.5
	c-2.8-6.4-2.9-7.2-2.9-13.3V108c0-10-7.7-15.1-20.3-15.1c-12.7,0-20.4,4.9-20.4,14.8h11.5c0.1-4,3.5-6,8.9-6c5.7,0,8.9,1.9,8.9,6.1
	c0,11.2-31.1,0.5-31.1,20.8c0,9.1,6.6,13.9,15.3,13.9c7.1,0,12.8-3.2,15.8-8.8l2.8,7.9H246.6z M213,127.3c0-7.9,12.5-7.3,19.4-11.6
	v6c0,7.9-5.5,12.2-11.3,12.2C215.9,133.8,213,131.8,213,127.3z M250,124.7c0,12.3,6.3,18,15.5,18c6.8,0,12.3-3.1,15-9.1v8h11V94
	h-11.4v27.6c0,7.7-3.9,11.6-9.8,11.6c-5.7,0-8.9-3.2-8.9-10.2V94H250V124.7z"
      fill="#001AFF"
    />
  </svg>
)
const Splash = () => {
  const [ready, setReady] = useState()
  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 150)
  }, [])

  return (
    <div className="splash">
      {ready && (
        <motion.div
          animate={{
            opacity: 0,
            transition: {
              delay: 4,
              duration: 1,
            },
            transitionEnd: {
              display: "none",
            },
          }}
        >
          <div
            id="splash"
            className={
              "fixed bottom-0 w-full  p-sm z-50 bg-secondary- pointer-events-none mix-blend-difference  "
            }
          >
            <motion.div
              animate={{
                opacity: 0,
                transition: {
                  delay: 2,
                  duration: 1,
                },
                transitionEnd: {
                  display: "none",
                },
              }}
            >
              {isMobile ? <MobileLogo /> : <DesktopLogo />}
            </motion.div>
          </div>
        </motion.div>
      )}
    </div>
  )
}

export default Splash
